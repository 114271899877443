<div class="mobile-modal__inner">
    <div class="m-portlet m-portlet--mobile">
        <div class="m-portlet__body mx-3">
            <div class="box-title static-title w-100">
                <i class="fas fa-info-circle readingHistory-icon"></i>
                {{ $t('widget.i.title') }}
                <button
                    @click="$emit('toggleWidget', 'changeBillingDataWidget')"
                    class="btn btn-link px-0 float-end btn-nt"
                    v-if="!displayElement('hide-change-billing-address-link') && billingAddress.loaded"
                >
                    {{ $t('widget.ig.changeBillingAddress') }}
                </button>
                <i
                    v-else-if="$t('widget.ig.titel.tooltip')"
                    class="fas fa-question-circle"
                    v-tooltip.top="$t('widget.ig.titel.tooltip')"
                ></i>
            </div>
            <div class="mobile-modal__wrap mx-0 pb-3">
                <div class="row mb-4 pb-2" v-if="isLoading">
                    <content-loader
                        height="20"
                        class="col-12"
                        primaryColor="var(--secondary)"
                        secondaryColor="var(--primary)"
                        data-test="CONTENT-LOADER"
                    ></content-loader>
                </div>
                <template v-else>
                    <div
                        class="row"
                        v-if="!isLoading && isInvoicesGeneralLoadedWithError"
                    >
                        <div class="col-12 text-center">
                            {{ $t('widgets.load.error.message') }}
                        </div>
                    </div>
                    <div class="row mb-4 pb-2" v-else>
                        <div class="col-12 col-md-8 border-right-md">
                            <div class="row">
                                <div class="col">
                                    <div class="mb-3">
                                        <strong>{{ contract.title }}</strong>
                                    </div>
                                    <div v-if="tariff.basePrice">
                                        {{ tariff.basePrice | euro }} {{
                                        $t('euro') }}/{{ $t('year') }}
                                        <template
                                            v-if="showPriceInformationAsterisk"
                                            >*<sup>1</sup></template
                                        >
                                    </div>
                                    <template
                                        v-for="workingPrice in tariff.workingPrices"
                                    >
                                        <template
                                            v-if="tariff.workingPrices.length > 1"
                                            >{{ workingPrice.registerName }}:
                                        </template>
                                        {{ workingPrice.workingPriceBrutto |
                                        euro }} {{ commodityPrice }}
                                        <br
                                            v-if="tariff.workingPrices.length > 1"
                                        />
                                    </template>
                                </div>
                                <div class="col">
                                    <div class="mb-3">
                                        <strong>
                                            {{ $t('widget.ig.deliveryPoint') }}
                                        </strong>
                                    </div>
                                    <div>
                                        {{ contract.street }} {{
                                        contract.houseNumber }}
                                    </div>
                                    <div>
                                        {{ contract.zip }} {{ contract.city }}
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="mb-3">
                                        <strong>
                                            {{ $t('widget.ig.billingAddress') }}
                                        </strong>
                                    </div>
                                    <div
                                        v-if="isPostboxEnabled && billingAddress.postbox"
                                    >
                                        {{ $t('widget.cbd.postbox') }}: {{
                                        billingAddress.postbox }}
                                    </div>
                                    <div>
                                        {{ billingAddress.street }} {{
                                        billingAddress.houseNumber }}
                                    </div>
                                    <div>
                                        {{ billingAddress.zip }} {{
                                        billingAddress.city }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 mt-4 mt-md-0">
                            <div class="row">
                                <div
                                    :class="displayElement('hide-invoice-general-meter-read-info') ? 'col-12' : 'col-6 border-right'"
                                >
                                    <div
                                        class="text-center d-flex flex-column h-100"
                                    >
                                        <div class="h3 mt-auto">
                                            <i
                                                class="fas fa-funnel-dollar font-size-20"
                                            ></i>
                                            {{ accountBalance | euroSpecial }}
                                            {{ $t('euro') }}
                                        </div>
                                        <div class="mb-auto">
                                            {{ $t('widget.ab.title') }}
                                            <i
                                                class="fas fa-question-circle text-primary-color font-size-12"
                                                v-tooltip.top="$t('widget.ab.title.tooltip')"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="!displayElement('hide-invoice-general-meter-read-info') "
                                    class="col-6"
                                >
                                    <div
                                        class="text-center d-flex flex-column h-100"
                                    >
                                        <template v-if="inRegistrationState">
                                            <div class="h3 my-auto">
                                                <i
                                                    class="far fa-calendar font-size-20"
                                                ></i>
                                                {{
                                                $t('widget.m.textWaitingUnclearDate')
                                                }}
                                            </div>
                                        </template>
                                        <template
                                            v-else-if="state === 'waiting'"
                                        >
                                            <div class="h3 mt-auto">
                                                <i
                                                    class="far fa-calendar font-size-20"
                                                ></i>
                                                {{ daysUntil }} {{ daysUntil ===
                                                1 ? $t('widget.m.day') :
                                                $t('widget.m.days') }}
                                            </div>
                                            <div class="mb-auto">
                                                {{ $t('widget.m.textWaiting') }}
                                            </div>
                                        </template>
                                        <template
                                            v-else-if="state === 'waitingPast'"
                                        >
                                            <div class="h3 my-auto">
                                                <i
                                                    class="far fa-calendar font-size-20"
                                                ></i>
                                                {{
                                                $t('widget.m.textWaitingUnclearDate')
                                                }}
                                            </div>
                                        </template>
                                        <template
                                            v-else-if="state === 'moveInMissing'"
                                        >
                                            <div class="h3 mt-auto">
                                                <i
                                                    class="far fa-calendar font-size-20"
                                                ></i>
                                                {{ $t('widget.i.now') }}
                                            </div>
                                            <div class="mb-auto">
                                                {{
                                                $t('widget.i.textMoveInMissing')
                                                }}
                                            </div>
                                        </template>
                                        <template
                                            v-else-if="state === 'moveOutMissing' && daysUntil <= 0"
                                        >
                                            <div class="h3 mt-auto">
                                                <i
                                                    class="far fa-calendar font-size-20"
                                                ></i>
                                                {{ $t('widget.i.now') }}
                                            </div>
                                            <div class="mb-auto">
                                                {{
                                                $t('widget.m.textMoveOutMissingNow')
                                                }}
                                            </div>
                                        </template>
                                        <template
                                            v-else-if="state === 'moveOutMissing' && daysUntil >= 1"
                                        >
                                            <div class="h3 mt-auto">
                                                <i
                                                    class="far fa-calendar font-size-20"
                                                ></i>
                                                {{ daysUntil }} {{ daysUntil ===
                                                1 ? $t('widget.m.day') :
                                                $t('widget.m.days') }}
                                            </div>
                                            <div class="mb-auto">
                                                {{
                                                $t('widget.m.textMoveOutMissing')
                                                }}
                                            </div>
                                        </template>
                                        <template
                                            v-else-if="state === 'turnusNow'"
                                        >
                                            <div class="h3 mt-auto">
                                                <i
                                                    class="far fa-calendar font-size-20"
                                                ></i>
                                                {{ $t('widget.i.now') }}
                                            </div>
                                            <div class="mb-auto">
                                                {{ $t('widget.m.textTurnusNow')
                                                }}
                                            </div>
                                        </template>
                                        <template
                                            v-else-if="state === 'closed' && finalInvoiceCreated"
                                        >
                                            <div class="h3 mt-auto">
                                                <i
                                                    class="far fa-calendar font-size-20"
                                                ></i>
                                                {{ $t('widget.m.finalInvoice')
                                                }}
                                            </div>
                                            <div class="mb-auto">
                                                {{ $t('widget.m.hasBeenCreated')
                                                }}
                                            </div>
                                        </template>
                                        <template
                                            v-else-if="state === 'closed'"
                                        >
                                            <div class="h3 mt-auto">
                                                <i
                                                    class="far fa-calendar font-size-20"
                                                ></i>
                                                {{ $t('widget.m.deliveryEnd') }}
                                            </div>
                                            <div class="mb-auto">
                                                {{ $t('widget.m.hasBeenReached')
                                                }}
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="h3 mt-auto">
                                                <i
                                                    class="far fa-calendar font-size-20"
                                                ></i>
                                                {{ daysUntil }} {{ daysUntil ===
                                                1 ? $t('widget.m.day') :
                                                $t('widget.m.days') }}
                                            </div>
                                            <div class="mb-auto">
                                                {{ $t('widget.m.text') }}
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <table
                    v-if="!isLoading && !isInvoicesGeneralLoadedWithError"
                    :class="['table table-striped d-block overflow-auto', displayElement('table-invoiceList-sm') ? 'table-invoiceList-sm' : '']"
                    id="invoiceList-content"
                >
                    <colgroup v-if="displayElement('table-fixed-columns')">
                        <col style="width: 28%" />
                        <col style="width: 36%" />
                        <col style="width: 20%" />
                        <col style="width: 16%" />
                    </colgroup>

                    <tbody>
                        <tr>
                            <th
                                :data-name="$t('widget.i.billNumber')"
                                class="d-block"
                            >
                                <span>{{ $t('widget.i.billNumber') }}</span>
                            </th>
                            <th data-name="Zeitraum">
                                <span>{{ $t('widget.i.billCycle') }}</span>
                            </th>
                            <th data-name="Betrag">
                                <span>{{ $t('widget.i.amount') }}</span>
                            </th>
                            <th data-name="Details">
                                <span>{{ $t('widget.i.details') }}</span>
                            </th>
                        </tr>
                        <tr
                            v-for="invoice in invoicesMapped"
                            :class="{'active': invoice.active}"
                        >
                            <td
                                data-name="mobile-main"
                                @click="activate(invoice)"
                            >
                                {{ invoice.invoiceNumber }}
                            </td>
                            <td :data-name="$t('widget.i.billCycle')">
                                {{ invoice.periodStart | date }} - {{
                                invoice.periodEnd | date }}
                            </td>
                            <td :data-name="$t('widget.i.amount')">
                                {{ (invoiceShowUnsettledValue ?
                                invoice.unsettledValue : invoice.value) | euro
                                }} {{ $t('euro') }}
                                <template v-if="invoice.sum < 0"
                                    >({{ $t('credit') }})</template
                                >
                            </td>
                            <td>
                                <a
                                    v-if="displayElement('downloadInvoice-link')"
                                    href="#"
                                    @click.prevent="downloadInvoice(invoice)"
                                >
                                    <strong>
                                        {{ $t('widget.i.download') }}
                                    </strong>
                                </a>
                                <button
                                    v-else
                                    :class="[
                                        'btn btn-sm btn-invoice-download',
                                        displayElement('download-btn-link') ? 'btn-link' : 'btn-primary'
                                    ]"
                                    @click="downloadInvoice(invoice)"
                                >
                                    <span>{{ $t('widget.i.download') }}</span>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
